export default function Mixins() {
    return {
        methods: {
            toCurrency(number) {
                return parseFloat(Math.round(number * 100) / 100)
                    .toFixed(2)
                    .replace('.', ',');
            },
            toCurrencyLong(number) {
                return parseFloat(Math.round(number * 1000000) / 1000000)
                    .toFixed(6)
                    .replace('.', ',');
            },
            toCurrencyShort(number) {
                return parseFloat(Math.round(number * 100) / 100)
                    .toFixed(2)
                    .replace('.', ',')
                    .replace(',00', ',-');
            },
            toCurrencyShortEur(number) {
                return `€ ${parseFloat(Math.round(number * 100) / 100)
                    .toFixed(2)
                    .replace('.', ',')
                    .replace(',00', ',-')}`;
            },
            toCurrencyVeryShortEur(number) {
                return `€ ${parseFloat(Math.round(number * 100) / 100)
                    .toFixed(2)
                    .replace('.', ',')
                    .replace(',00', '')}`;
            },
            toCurrencyEur(number) {
                return `€ ${parseFloat(Math.round(number * 100) / 100)
                    .toFixed(2)
                    .replace('.', ',')}`;
            },
            toCurrencyLongEur(number) {
                return `€ ${parseFloat(Math.round(number * 1000000) / 1000000)
                    .toFixed(5)
                    .replace('.', ',')}`;
            },
            toDecimal(number, digits) {
                return parseFloat(Math.round(number * 10 * digits) / (10 * digits))
                    .toFixed(digits)
                    .replace('.', ',');
            },
            toScore(number) {
                if (number === 10 || number === 0) {
                    return Math.round(number);
                } if (number == null) {
                    return '-';
                }
                return this.toDecimal(number, 1);
            },
            toLocaleNumberFormat(number) {
                return number.toLocaleString('nl-NL');
            },

            logAnalyticsEvent(eventName, eventData) {
                if (typeof window.bananas !== 'undefined') {
                    window.bananas('event', eventName, eventData);
                } else {
                    // eslint-disable-next-line no-console
                    console.log('banalytics not active, would like to log:', eventName, eventData);
                }
            },
            toDateString(date) {
                const options = {
                    year: 'numeric', month: 'long', day: 'numeric',
                };
                return new Date(date).toLocaleDateString('nl-NL', options);
            },
            capitalize(word) {
                return word ? word.charAt(0).toUpperCase() + word.slice(1) : null;
            },
        },
    };
}
